import AxiosWrapper from '@/helpers/axios-wrapper'

/**
 * @returns Promise
 */
export function list(clientId) {
    const params = {clientId: clientId};
    return AxiosWrapper('/apps_client/list/', {params});
}

/**
 * @returns Promise
 */
export function listall(params) {
    return AxiosWrapper('/apps_client/all/list/', {params});
}

/**
 * @returns Promise
 * @param id
 * @param clientId
 * @param appId
 */
export function detail(id, clientId, appId) {
    const params = {id: id, clientId: clientId, appId: appId};
    return AxiosWrapper('/' +
        'apps_client/detail/', {params});
}

/**
 * @returns Promise
 */
export function store(formData) {
    return AxiosWrapper('/apps_client/store/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function edit(formData) {
    return AxiosWrapper('/apps_client/edit/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function deleteElement(formData) {
    return AxiosWrapper('/apps_client/delete_one/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function deleteElements(formData) {
    return AxiosWrapper('/apps_client/delete_list/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function enableElement(formData) {
    return AxiosWrapper('/apps_client/enable_one/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function enableElements(formData) {
    return AxiosWrapper('/apps_client/enable_list/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function disableElement(formData) {
    return AxiosWrapper('/apps_client/disable_one/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function disableElements(formData) {
    return AxiosWrapper('/apps_client/disable_list/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function executeNow(appId) {
    const params = {appId: appId};
    return AxiosWrapper('/apps_client/execute/', {params});
}

/**
 * @returns Promise
 */
export function executeElements(formData) {
    return AxiosWrapper('/apps_client/execute_list/', {headers: {"Content-Type": "multipart/form-data"}}, 'POST', formData);
}

/**
 * @returns Promise
 */
export function executeDebug(appId) {
    const params = {appId: appId};
    return AxiosWrapper('/apps_client/execute_debug/', {params});
}