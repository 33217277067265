<template>
  <div class="input-group input-group-sm">
    <textarea
      ref="textarea"
      rows="1"
      :value="value"
      class="form-control"
      aria-label="Sizing example input"
      aria-describedby="inputGroup-sizing-sm"
      placeholder="Set a value"
      @input="resizeTextarea"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "InputJSONField",
  props: {
    value: {
      type: String,
      required: false,
      default: null
    }
  },
  mounted() {
    this.resizeTextarea();
  },
  methods: {
    resizeTextarea(event) {
      const textarea = this.$refs.textarea;
      if (textarea) {
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    }
  }
};
</script>

<style scoped>
textarea {
  overflow-y: hidden;
  resize: none;
}
</style>
